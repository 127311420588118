module.exports = {
    siteUrl: `https://www.twoghoststrategy.com`, // Site domain. Do not include a trailing slash!

    postsPerPage: 12, // Number of posts shown on paginated pages (changes this requires sometimes to delete the cache)

    siteTitleMeta: `TWOGHOSTS Creative, Branding, and Digital`, // This allows an alternative site title for meta data for pages.
    siteDescriptionMeta: `A scrappy creative, brand, and digital marketing, advertising, and consulting firm out of Raleigh, North Carolina`, // This allows an alternative site description for meta data for pages.

    shareImageWidth: 1000, // Change to the width of your default share image
    shareImageHeight: 523, // Change to the height of your default share image

    shortTitle: `TWOGHOSTS`, // Used for App manifest e.g. Mobile Home Screen
    siteIcon: `favicon.png`, // Logo in /static dir used for SEO, RSS, and App manifest
    backgroundColor: `#121212`, // Used for Offline Manifest
    themeColor: `#ffffff`, // Used for Offline Manifest
}
